main.addlisting form{
}

main.addlisting form label{
    font-weight: 400;
    font-size: 11px;
    letter-spacing: 0.5px;
    line-height: 18px;
    color: #C6C6C6;
    text-transform: uppercase;
}

main.addlisting form input[type='name'],main.addlisting form input[type='number'],main.addlisting form input[type='description'], main.addlisting form textarea{
    width: 100%;
    padding: .75rem 1rem;
    background: #262626;
    color: #FFFFFF;
    box-shadow: inset 0px -1px 0px #6F6F6F;
}

main.addlisting form .btn-main{
    font-weight: 600;
    padding: 1.15rem;
}
