main.settings form{
}

main.settings form label{
    font-weight: 400;
    font-size: 11px;
    letter-spacing: 0.5px;
    line-height: 18px;
    color: #C6C6C6;
    text-transform: uppercase;
}

main.settings form input{
    width: 100%;
    padding: .75rem 1rem;
    background: #262626;
    color: #FFFFFF;
    box-shadow: inset 0px -1px 0px #6F6F6F;
}

main.settings form .btn-main, main.settings form .btn-main-inverse{
    font-weight: 600;
    padding: 1.15rem;
    border: 1px solid var(--main-color);
}
