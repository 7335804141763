@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

.nav-title{
    font-size: 22px;
    line-height: 26px;
    font-weight: 400;
    color: white;
}

#search-navbar{
    background: #1F1F1F;
    border: 1px solid #2C2C2C;
    color: #CCC;
    outline: none;
    border-radius: 30px;
}

#search-navbar::placeholder{
    color: rgb(115,115,115)
}

.authnav-button{
    padding: 0.75rem 2rem 0.75rem 2rem;
    border-radius: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.46px;
    background: transparent;
    text-transform: uppercase;
    color: #111;
    border:1px solid #111;
}

@media only screen and (max-width: 768px) {
  nav .btn-main,nav .btn-main-alt{
    margin-top: 20px;
  }
}