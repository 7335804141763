.main p{
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 1.8;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 30px;
}

.features h6{
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: rgba(255, 255, 255, 0.55);
    margin-bottom: 15px;
}

.features p{
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 26px;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 15px;
}

.getstarted p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #CCC;
    margin-bottom: 30px;
}

.getstarted .btn-getstarted{
  padding: 0.75rem 2rem 0.75rem 2rem;
  border-radius: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.16px;
  background: white;
  color: #161616;
}

.feature4bg{
    background-color: #F4CB38;
}

.sm-toggler{
    display: none;
}

@media only screen and (max-width: 768px) {
    .features{
        margin-top: 30px;
    }

    .main .heading{
        font-size: 44px !important;
    }

    .feature4bg{
        background-color: transparent;
    }

    .feature4bg h4{
        color: white !important;
    }
    
    nav #navbar-cta{
        transition: 3s ease;
        width: 0%;
        opacity: 0;
    }

    nav.toggled #navbar-cta{
        position: fixed;
        z-index: 99;
        background-color: #17171B;
        top: 0;
        margin-left: calc(25% - 1rem);
        opacity: 1;
        width: 75%;
        height: 100vh;
        border-left: 1px solid #99999950;
        padding: 30px 10px;

        --tw-shadow: 0 0px 30vw 30vw rgba(0,0,0,0.7);
        --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    .sm-toggler{
        display: flex;
    }

    .nav-buttons{
        display: none;
    }
}