nav, main, footer{
    width: 100%;
}

.authform{
    background-color: #393939;
    box-shadow: 0px 62px 30px -14px rgba(100, 100, 100, 0.0784314);
}

.authform .fields label{
    font-weight: 400;
    font-size: 11px;
    letter-spacing: 0.5px;
    line-height: 18px;
    color: #FFFFFF;
    text-transform: uppercase;
}

.authform input{
    width: 100%;
    padding: .5rem 0rem;
    background: transparent;
    color: #FFFFFF;
}

.authform .btn-main{
    font-weight: 600;
    padding: 1.15rem;
}

.authform .radios {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.authform .radio input {
    pointer-events: none;
    visibility: hidden;
}

.authform .radio input:focus+label {
    background: transparent;
}

.authform .radio input:focus+label .checker {
    border-color: #EDAE49;
}

.authform .radio input:checked+label .checker {
    box-shadow: inset 0 0 0 4px #FFF;
}

.authform .radio input:checked+label {
    background: #EDAE49;
    color: white;
}

.authform .radio label {
    text-transform: capitalize;
    display: flex;
    align-items: center;
    height: 28px;
    border-radius: 30px;
    margin-right: 10px;
    font-size: 15px;
    margin-top: -20px !important;
    margin-bottom: 0px !important;
    color: #FFF;
    /*        padding: 0 15px 0 8px;*/
    padding: 18px 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    background: transparent;
}

.authform .radio label:hover {
    background: #EDAE4930;
}

.authform .radio label:hover .checker {
    box-shadow: inset 0 0 0 2px #EDAE49;
}

.authform .radio .checker {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-right: 8px;
    box-shadow: inset 0 0 0 2px #ccc;
    transition: box-shadow 0.3s ease;
}

.authform .radio label.active {
    background: #EDAE49;
    color: #000;
}

.authform .radio label.active .checker {
    background: #EDAE49;
    color: #000;
    box-shadow: inset 0 0 0 2px #FFF;
}

@media only screen and (max-width: 768px) {
    .authform{
        width: 92%;
        margin-left: auto;
        margin-right: auto;
    }
  }